/* eslint-disable no-restricted-imports */
import { withSentryReactRouterV6Routing } from "@sentry/react";
import type { To } from "history";
import { Routes } from "react-router-dom";

// Compatibility layer between Remix and react-router-dom
import { Link, type LinkProps } from "./Link/Link";
import { NavLink } from "./NavLink/NavLink";
import { Navigate } from "./Navigate/Navigate";
import { Outlet } from "./Outlet/Outlet";
import { ScrollRestoration } from "./ScrollRestoration/ScrollRestoration";
import { useBlocker } from "./useBlocker/useBlocker";
import { useLocation } from "./useLocation/useLocation";
import { useMatch } from "./useMatch/useMatch";
import { useNavigate } from "./useNavigate/useNavigate";
import { useParams } from "./useParams/useParams";
import { useSearchParams } from "./useSearchParams/useSearchParams";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

/* eslint-disable import/export */
export * from "react-router-dom";
export {
  SentryRoutes as Routes,
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
  useMatch,
  useSearchParams,
  Link,
  NavLink,
  Navigate,
  Outlet,
  ScrollRestoration,
  type To,
  type LinkProps,
};
